import useAuth from "app/hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const token = Cookies.get("authToken");

  if (token) return <>{children}</>;

  return <Navigate replace to="/signin" state={{ from: pathname }} />;
};

export default AuthGuard;
