import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import materialRoutes from "app/views/material-kit/MaterialRoutes";
import { SelectedItemProvider } from "./views/dashboard/shared/SelectedItemContex";

const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const ResetPassword = Loadable(
  lazy(() => import("app/views/sessions/ResetPassword"))
);
const PaymentLoading = Loadable(
  lazy(() => import("app/views/dashboard/UpdatePayment"))
);
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/testregform"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);
const QR = Loadable(lazy(() => import("app/views/sessions/Shipit")));
const CheckOut = Loadable(lazy(() => import("app/views/dashboard/Checkout")));
const ChoosePlan = Loadable(
  lazy(() => import("app/views/dashboard/payments/PaymentOptions"))
);
const ItemFound = Loadable(lazy(() => import("app/views/sessions/ItemFound")));

const AppEchart = Loadable(lazy(() => import("app/views/dashboard/tabs")));

// dashboard page
const Inventory = Loadable(lazy(() => import("app/views/dashboard/Inventory")));

// FormDialog page
const FormDialog = Loadable(
  lazy(() => import("app/views/material-kit/dialog/FormDialog"))
);

//Checklist page
const ChecklistItems = Loadable(
  lazy(() => import("app/views/dashboard/Checklist"))
);

//Checklist page
const ProductListPage = Loadable(
  lazy(() => import("app/views/dashboard/Products"))
);

//Dashboard page
const DefaultStatisticsCard = Loadable(
  lazy(() => import("app/views/dashboard/shared/defaultstatisticscard"))
);

// Payment
const PaymentsPage = Loadable(
  lazy(() => import("app/views/dashboard/payments/Payments"))
);

//Home page
const Home = Loadable(lazy(() => import("app/site/LandingPage")));
const Maintenance = Loadable(
  lazy(() => import("app/views/dashboard/maintenance"))
);

const MobileUpload = Loadable(
  lazy(() => import("app/views/dashboard/shared/MobileUpload"))
);

const CollectionAndCategoryTab = Loadable(
  lazy(() => import("app/views/dashboard/CollectionAndCategory"))
);

const ItemInfo = Loadable(
  lazy(() => import("app/views/dashboard/inventory/itemInfo"))
);

const routes = [
  {
    element: <MatxLayout />,
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: "/dashboard/default",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <SelectedItemProvider>
              <Inventory />
            </SelectedItemProvider>
          </AuthGuard>
        ),
      },

      {
        path: "/dashboard/collection-and-category",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <CollectionAndCategoryTab />
          </AuthGuard>
        ),
      },

      // e-chart route
      {
        path: "/dashboard/tabs",
        element: (
          <AuthGuard roles={authRoles.editor}>
            <AppEchart />
          </AuthGuard>
        ),
      },

      //checklist route
      {
        path: "/dashboard/Checklist",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <ChecklistItems />
          </AuthGuard>
        ),
      },
      //checklist route
      {
        path: "/dashboard/Products",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <ProductListPage />
          </AuthGuard>
        ),
      },
      {
        path: "/checkout/:sessionId",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <CheckOut />
          </AuthGuard>
        ),
      },
      // form dialog route
      {
        path: "/material-kit/dialog",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <FormDialog />
          </AuthGuard>
        ),
      },

      {
        path: "/dashboard/payments",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <PaymentsPage />
          </AuthGuard>
        ),
      },

      {
        path: "/dashboard/maintenance",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <Maintenance />
          </AuthGuard>
        ),
      },
      // Dashboard route
      {
        path: "/dashboard/Dashboard",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <DefaultStatisticsCard />
          </AuthGuard>
        ),
      },
    ],
  },

  {
    path: "/qr/item-info/:id",
    element: (
      <AuthGuard roles={authRoles.admin}>
        <ItemInfo />
      </AuthGuard>
    ),
  },

  { path: "/mobileUpload/:token/:ctokn", element: (<MobileUpload />)},

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/signin", element: <JwtLogin /> },
  { path: "/signup", element: <JwtRegister /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/payment", element: <ChoosePlan /> },
  { path: "/processing-payment", element: <PaymentLoading /> },
  { path: "/qr", element: <QR /> },
  { path: "/item-found", element: <ItemFound /> },
  { path: "/home", element: <Home /> },
  { path: "/", element: <Navigate to="/home" /> },
  { path: "*", element: <NotFound /> },
];
export default routes;
