import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "app/hooks/useAuth";

export const PaymentWrapper = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const exceptionURLs = [
      "/payment",
      "/checkout",
      "/processing-payment",
      "/payment?mode=no_trial",
      "/home",
      "/session/404",
    ];
    const firstPart = currentPath.substring(0, currentPath.indexOf("/", 1) + 1);
    if (user && !exceptionURLs.includes(firstPart)) {
      if (!user.hasStripe) {
        navigate("/payment", { replace: true });
      } else if (user.hasStripe && !user.hasPaid) {
        navigate("/payment?mode=no_trial", { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <>{children}</>;
};

export default PaymentWrapper;
